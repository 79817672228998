import "./App.css";
import { Box, LinearProgress, styled } from "@mui/material";
import { QuestionsList } from "./components/questionsList/QuestionsList";
import { useRateList } from "./api/rate";
import { useNavigate, useParams } from "react-router";
import Page from "./components/page";
import { useEffect } from "react";

export const StyledRoot = styled("main")(() => ({
  height: "100%",
  display: "flex",
  position: "relative",
  direction: "rtl",
}));
export const TitleHeader = styled("div")(() => ({
  fontWeight: 700,
  direction: "rtl",
  marginBottom: "16px",
}));

export const TitleContent = styled("div")(() => ({}));
export const QuestionsContainer = styled("div")(() => ({
  padding: "30px",
}));

function App() {
  const { id = "" } = useParams();

  const rateForm = useRateList(id);
  const navigate = useNavigate();
  useEffect(() => {
    if (rateForm.isSuccess && rateForm.data.submitDate) {
      navigate("/success");
    }
  }, [navigate, rateForm.data, rateForm.isSuccess]);

  return (
    <Page
      title="Rating"
      // prevent zooming
      style={{
        touchAction: "manipulation",
        userSelect: "none",
      }}
    >
      {(rateForm.isLoading || rateForm.isFetching) && (
        <LinearProgress color="inherit" />
      )}
      {rateForm.data && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "#FFF",
            pt: "24px",
            pb: "16px",
            px: "20px",
            textAlign: "center",
          }}
        >
          <TitleHeader>{`عزيزي/تي ${
            rateForm.data?.name ?? ""
          } نشكركم على زيارتكم لعيادات الكسائى الطبية ${
            rateForm.data?.branch ?? ""
          }`}</TitleHeader>
          <TitleContent>
            <div
              style={{
                direction: "rtl",
                marginBottom: "10px",
                fontWeight: "400px",
                fontSize: "14px",
                paddingRight: "28px",
                paddingLeft: "28px",
              }}
            >
              نحن نولى اهتماما كبيرا لتجربتك معنا
            </div>
          </TitleContent>
        </Box>
      )}
      {!rateForm.data || rateForm.data.submitDate ? (
        <div></div>
      ) : (
        <QuestionsContainer>
          <QuestionsList
            questions={rateForm.data.questions}
            rateId={rateForm.data.id}
          />
        </QuestionsContainer>
      )}
    </Page>
  );
}

export default App;
