import { Box, Button, styled } from "@mui/material";
import Page from "../../components/page";
import Image from "../../components/image";

const iconWidth = "25px";
const iconHeight = "25px";

const HeaderContainer = styled("div")(() => ({}));

const MainText = styled("span")(() => ({
  fontSize: "20px",
  fontWeight: 700,
  lineHeight: "24px",
  textAlign: "center",
  color: "#059238",
  direction: "rtl",
  display: "block",
}));

const SubText = styled("span")(() => ({
  fontSize: "20px",
  fontWeight: 700,
  lineHeight: "24px",
  textAlign: "center",
  color: "#000000",
  direction: "rtl",
  display: "block",
}));
export const SuccessPage = () => {
  const navigateToStore = () => {
    window.location.href = "http://app.kesay.sa/";
  };
  return (
    <Page title="Success">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "#FFF",
          pt: "40px",
          pb: "32px",
          px: "42px",
        }}
      >
        <HeaderContainer>
          <Box sx={{ textAlign: "center" }}>
            <Image
              src={"/img/heart.svg"}
              style={{
                width: iconWidth,
                height: iconHeight,
              }}
            />
          </Box>
          <Box>
            <MainText>شكرا لك !</MainText>
            <SubText sx={{ fontWeight: "500" }}>نتطلع للقائك قريبا !</SubText>
          </Box>
        </HeaderContainer>
      </Box>
      <Box
        sx={{
          backgroundColor: "#FFF",
          textAlign: "center",
          position: "relative",
        }}
      >
        <Image
          src={"/img/mobile.png"}
          style={{
            width: "511px",
            height: "412px",
          }}
        />
        <Box
          sx={{
            position: "absolute",
            bottom: "-42px",
            backgroundColor: "#fff",
            width: "100%",
            pt: "53px",
            pb: "30px",
            borderRadius: "11px",
            boxShadow: "0px 8px 30px 0px #C4C4C44D",
          }}
        >
          <span
            style={{
              direction: "rtl",
              fontWeight: 700,
              fontSize: "20px",
              lineHeight: "24px",
              color: "#059238",
            }}
          >
            حمل التطبيق الان
          </span>
          <span
            style={{
              direction: "rtl",
              fontWeight: 700,
              fontSize: "20px",
              lineHeight: "24px",
              color: "#9F9F9F",
            }}
          >
            ، واستمتع بعروضنا الحصرية لجمالك
          </span>
          <Box
            sx={{
              direction: "rtl",
              fontWeight: 400,
              fontSize: "14px",
              textAlign: "center",
              pt: "18px",
              pb: "20px",
              px: "31px",
            }}
          >
            حملي الان تطبيق الجوال لتتمكني من تصفح قائمة مميزة من الخدمات
            المصممة خصيصا لك ، ومن ثم  حجز موعدك بسهوله !
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box
              sx={{
                backgroundColor: "#000",
                borderRadius: "5px",
                width: "40px",
                height: "40px",
                mr: "12px",
                alignContent: "center",
                cursor: "pointer",
              }}
              onClick={navigateToStore}
            >
              <Image
                src={"/img/ios.svg"}
                style={{
                  width: "21px",
                  height: "24px",
                }}
              />
            </Box>
            <Box
              sx={{
                backgroundColor: "#00D24B",
                borderRadius: "5px",
                width: "40px",
                height: "40px",
                alignContent: "center",
                cursor: "pointer",
              }}
              onClick={navigateToStore}
            >
              <Image
                src={"/img/android.svg"}
                style={{
                  width: "24px",
                  height: "14px",
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ mt: "92px", textAlign: "center" }}>
        <span
          style={{
            direction: "rtl",
            fontWeight: 700,
            fontSize: "20px",
            lineHeight: "24px",
            color: "#059238",
          }}
        >
          دعينا نشاركك الرحلة ،{" "}
        </span>
        <span
          style={{
            direction: "rtl",
            fontWeight: 700,
            fontSize: "20px",
            lineHeight: "24px",
            color: "#000",
          }}
        >
          احجزي موعدك الان{" "}
        </span>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          mt: "32px",
          mb: "108px",
        }}
      >
        <Button
          sx={{ fontWeight: 700, fontSize: "18px", width: "80%" }}
          variant="outlined"
          color="inherit"
          LinkComponent={"a"}
          href="tel:+966920010359"
        >
          تواصل معنا
        </Button>
      </Box>
    </Page>
  );
};
