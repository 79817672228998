import { Button, styled } from "@mui/material";
import { Question, SubmitRateRequest } from "../../types/Rate";
import { QuestionItem } from "./QuestionItem";
import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { submitRate } from "../../api/rate";
import useResponsive from "../../hooks/useResponsive";
import { useNavigate } from "react-router";

export const HeaderContainer = styled("h3")<{ isDesktop: boolean }>(
  ({ isDesktop }) => {
    if (isDesktop) {
      return {
        textAlign: "end",
        paddingRight: "12px",
      };
    }

    return {
      textAlign: "end",
      paddingLeft: "16px",
      paddingRight: "5px",
      paddingTop: "5px",
      alignSelf: "flex-end",
      color: "#778c7d",
      fontWeight: "bold",
    };
  }
);

type Props = {
  rateId: number;
  questions: { [key: string]: Question[] };
};
export const QuestionsList = ({ questions, rateId }: Props) => {
  const isDesktop = useResponsive("up", "lg");
  const navigate = useNavigate();

  const submitMutation = useMutation({
    mutationFn: (request: SubmitRateRequest) => submitRate(request),
  });

  const keys = Object.keys(questions);
  const [rateAnswers, setRateAnswers] = useState<
    { Id: number; Answer: number | null }[]
  >(
    Object.values(questions)
      .flat()
      .flat()
      .map((x) => ({ Id: x.id, Answer: x.answer }))
  );

  const onAnswerChange = (answer: { Id: number; Answer: number }) => {
    setRateAnswers((prev) => {
      const newAnswers = [...prev];
      const index = newAnswers.findIndex((x) => x.Id === answer.Id);
      newAnswers[index] = answer;
      return newAnswers;
    });
  };

  const onSubmit = async () => {
    const request: SubmitRateRequest = {
      RateId: rateId,
      RateAnswers: rateAnswers,
    };
    var result = await submitMutation.mutateAsync(request);
    if (result) {
      navigate("/success");
    } else {
      navigate("/error");
    }
  };
  return (
    <>
      {keys.map((key) => (
        <div
          key={key}
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <HeaderContainer isDesktop={isDesktop}>{key}</HeaderContainer>
          {questions[key].map((question) => (
            <QuestionItem
              question={question}
              key={question.id}
              answer={
                rateAnswers.find((x) => x.Id === question.id)?.Answer ??
                question.answer
              }
              onAnswerChange={onAnswerChange}
            />
          ))}
        </div>
      ))}
      <div
        style={{
          width: "100%",
          textAlign: "center",
          marginTop: "40px",
          marginBottom: "40px",
        }}
      >
        <Button
          variant="contained"
          onClick={onSubmit}
          sx={{
            width: isDesktop ? "20%" : "80%",
            height: "50px",
            borderRadius: "20px",
            color: "#000",
            fontWeight: "bold",
            fontFamily: "Tajawal",
            backgroundColor: "#88a790",
            "&:hover": {
              backgroundColor: "#88a790",
            },
          }}
        >
          ارسال التقييم
        </Button>
      </div>
    </>
  );
};
