import axios from 'axios';
// config
import { HOST_API_KEY } from '../config';

// ----------------------------------------------------------------------

const dateTransformer = (data: any): any => {
  if (data instanceof FormData) return data;
  if (data instanceof Date) {
    // do your specific formatting here
    return data.toLocaleString('en-us');
  }
  if (Array.isArray(data)) {
    return data.map((val) => dateTransformer(val));
  }
  if (typeof data === 'object' && data !== null) {
    return Object.fromEntries(
      Object.entries(data).map(([key, val]) => [key, dateTransformer(val)])
    );
  }
  return data;
};

const axiosInstance = axios.create({
  baseURL: HOST_API_KEY,
  transformRequest: [dateTransformer].concat(axios.defaults.transformRequest as any),
});

axiosInstance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject((error.response && error.response.data) || 'Something went wrong');
  }
);

export default axiosInstance;
