import { forwardRef } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, ...other }, ref) => {
    const theme = useTheme();

    const color = theme.palette.mode === 'light' ? '#000' : '#fff';

    const logo = (
      <Box
        ref={ref}
        component="div"
        sx={{
          width: 120,
          height: 40,
          display: 'inline-flex',
          ...sx,
        }}
        {...other}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 990.812 157.813">


          <g fill={color} fillRule="evenodd" stroke="none" strokeWidth="1">
            <path id="K_E_S_A_Y" data-name="K | E | S | A | Y" d="M148.285,522.6h-20.6V638.513h20.6V594.731l5.046-5.669,31.818,49.451h25.79l-42.75-63.941L210.8,522.6H184.448l-33.64,42.68-3.083,4.409,0.56-5.827V522.6ZM270.65,675.838h17.661V526.066H270.65V675.838ZM359.8,638.513h72.326V616.465h-52V591.424h44.293V569.375H380.119V544.649h50.039V522.6H359.8V638.513Zm137.643,37.325H515.1V526.066H497.438V675.838ZM649.377,533.153a68.153,68.153,0,0,0-13.946-7.875q-7.779-3.308-20.254-3.307a35.878,35.878,0,0,0-16.82,4.8,30,30,0,0,0-11.283,11.733,36.3,36.3,0,0,0-4,17.482,32.666,32.666,0,0,0,2.733,14.095,29.205,29.205,0,0,0,7.149,9.607,49.729,49.729,0,0,0,9.461,6.536q5.046,2.679,9.811,4.724a42.964,42.964,0,0,1,7.5,4.1,21.89,21.89,0,0,1,5.467,5.2,10.581,10.581,0,0,1,2.032,6.3q0,5.041-2.453,7.874a13.32,13.32,0,0,1-6.167,4.016,24.171,24.171,0,0,1-7.359,1.181,28.8,28.8,0,0,1-7.989-1.259,44.632,44.632,0,0,1-8.62-3.544,48.045,48.045,0,0,1-7.92-5.276l-8.97,17.8a59.649,59.649,0,0,0,11.353,7.009,56.9,56.9,0,0,0,11.984,4.094,55.874,55.874,0,0,0,11.984,1.339q11.492,0,19.343-4.646a31.253,31.253,0,0,0,11.914-12.2,34.053,34.053,0,0,0,4.065-16.379,41.305,41.305,0,0,0-2.1-14.1,28.865,28.865,0,0,0-5.607-9.607,33.851,33.851,0,0,0-7.919-6.457,74.84,74.84,0,0,0-8.9-4.489,94.545,94.545,0,0,1-9.461-4.567,27.791,27.791,0,0,1-7.429-5.748,10.709,10.709,0,0,1-2.873-7.324q0-5.983,3.784-8.977t10.373-2.992a31.742,31.742,0,0,1,11.423,2.362,73.654,73.654,0,0,1,11.984,5.985Zm63.215,142.685h17.661V526.066H712.592V675.838Zm97.555-37.325L836,568h0l24.747,70.513H884.3L837.76,518.034h-1.121L790.1,638.513h20.043ZM818,616h35l-7-19H825Zm125.585,59.838h17.661V526.066H943.585V675.838ZM1059.5,638.513h20.33V602.448l38.68-79.847h-25.09L1071,576h-1l-23.39-53.4h-25.09l37.98,79.847v36.065Z" transform="translate(-127.688 -518.031)" />

          </g>
        </svg>
      </Box>
    );

    return (
      <>{logo}</>
    );
  }
);

export default Logo;
