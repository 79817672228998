import { useQuery } from "@tanstack/react-query";
import { RateForm, SubmitRateRequest } from "../types/Rate";
import axios from "../utils/axios";

async function getRate(urlId: string) {
    const response = await axios.get(`api/rate/${urlId}`);
    return response.data as RateForm;
}

export async function submitRate(request: SubmitRateRequest) {
    const response = await axios.post('api/rate/submit', request);
    return response.data as RateForm;
}

export function useRateList(urlId: string) {
    return useQuery({queryKey: ["rate", urlId], queryFn: () => getRate(urlId)});
  }
  