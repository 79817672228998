import { forwardRef, ReactNode } from "react";
// @mui
import { Box, BoxProps } from "@mui/material";
import Header, { HEADER } from "../header/Header";
import useResponsive from "../../hooks/useResponsive";

// ----------------------------------------------------------------------
const SPACING = 8;

interface Props extends BoxProps {
  children: ReactNode;
  title: string;
}
const Page = forwardRef<HTMLDivElement, Props>(
  ({ children, title = "", ...other }, ref) => {
    const isDesktop = useResponsive("up", "lg");

    return (
      <>
        <title>{`${title} `}</title>
        <Header />
        <Box
          ref={ref}
          sx={{
            display: { lg: "flex" },
            minHeight: { lg: 1 },
          }}
          {...other}
        >
          <Box
            component="main"
            sx={{
              pt: `${HEADER.H_MOBILE}px`,
              pb: `${HEADER.H_MOBILE + SPACING}px`,
              width: "100%",
              ...(isDesktop && {
                pt: `${HEADER.H_DASHBOARD_DESKTOP}px`,
                pb: `${HEADER.H_DASHBOARD_DESKTOP + SPACING}px`,
              }),
            }}
          >
            {children}
          </Box>
        </Box>
      </>
    );
  }
);

export default Page;
