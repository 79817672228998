import { styled } from "@mui/material";
import { Question } from "../../types/Rate";
import useResponsive from "../../hooks/useResponsive";

import Image from "../image/Image";

export const Container = styled("div")(() => ({
  textAlign: "end",
  paddingRight: "12px",
  marginBottom: "16px",
}));
export const Title = styled("div")(() => ({
  fontWeight: 500,
  marginBottom: "15px",
}));
export const AnswersContainer = styled("div")<{ isDesktop: boolean }>(
  ({ isDesktop }) => {
    if (isDesktop) {
      return {
        display: "flex",
        columnGap: "4px",
        justifyContent: "flex-end",
      };
    }
    return {
      display: "flex",
      columnGap: "8px",
      justifyContent: "center",
    };
  }
);
export const Answer = styled("div")(() => ({
  textAlign: "center",
  cursor: "pointer",
  "&:hover.green": {
    "& > div": {
      backgroundColor: "#00AB55",
      transition: "background-color 0.3s ease",
      ":hover": {
        transform: "scale(1.02)",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
      },
    },
  },
  "&.bg-green": {
    "& > div": {
      backgroundColor: "#00AB55",
      transition: "background-color 0.3s ease",
    },
  },

  "&:hover.light-green": {
    "& > div": {
      backgroundColor: "#9CB959",
      transition: "background-color 0.3s ease",
      ":hover": {
        transform: "scale(1.02)",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
      },
    },
  },
  "&.bg-light-green": {
    "& > div": {
      backgroundColor: "#9CB959",
      transition: "background-color 0.3s ease",
    },
  },

  "&:hover.yellow": {
    "& > div": {
      backgroundColor: "#FDCF3B",
      transition: "background-color 0.3s ease",
      ":hover": {
        transform: "scale(1.02)",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
      },
    },
  },
  "&.bg-yellow": {
    "& > div": {
      backgroundColor: "#FDCF3B",
      transition: "background-color 0.3s ease",
    },
  },

  "&:hover.orange": {
    "& > div": {
      backgroundColor: "#F29E1E",
      transition: "background-color 0.3s ease",
      ":hover": {
        transform: "scale(1.02)",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
      },
    },
  },
  "&.bg-orange": {
    "& > div": {
      backgroundColor: "#F29E1E",
      transition: "background-color 0.3s ease",
    },
  },

  "&:hover.red": {
    "& > div": {
      backgroundColor: "#E66934",
      transition: "background-color 0.3s ease",
      ":hover": {
        transform: "scale(1.02)",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
      },
    },
  },
  "&.bg-red": {
    "& > div": {
      backgroundColor: "#E66934",
      transition: "background-color 0.3s ease",
    },
  },
}));

export const Line = styled("div")(() => ({
  height: "10px",
  width: "50px",
  borderRadius: "4px",
  backgroundColor: "#E0E2E0",
  marginTop: "8px",
}));

type Props = {
  question: Question;
  answer: number | null;
  onAnswerChange: (answer: { Id: number; Answer: number }) => void;
};

export const QuestionItem = ({ question, answer, onAnswerChange }: Props) => {
  const isDesktop = useResponsive("up", "lg");
  const classColor: { [key: number | string]: string } = {
    1: "bg-red",
    2: "bg-orange",
    3: "bg-yellow",
    4: "bg-light-green",
    5: "bg-green",
    "": "",
  };
  const hasValue = answer !== null;
  const iconWidth = "25px";
  const iconHeight = "25px";

  return (
    <Container>
      <Title>{question.questionText}</Title>
      <AnswersContainer isDesktop={isDesktop}>
        <Answer
          className={
            hasValue && answer === 5 ? classColor[answer ?? ""] : "green"
          }
          onClick={() => onAnswerChange({ Id: question.id, Answer: 5 })}
        >
          <Image
            src={"/img/happy.png"}
            style={{ width: iconWidth, height: iconHeight }}
          />
          <Line />
        </Answer>

        <Answer
          className={
            hasValue && answer === 4 ? classColor[answer ?? ""] : "light-green"
          }
          onClick={() => onAnswerChange({ Id: question.id, Answer: 4 })}
        >
          <Image
            src={"/img/good.png"}
            style={{ width: iconWidth, height: iconHeight }}
          />
          <Line />
        </Answer>

        <Answer
          className={
            hasValue && answer === 3 ? classColor[answer ?? ""] : "yellow"
          }
          onClick={() => onAnswerChange({ Id: question.id, Answer: 3 })}
        >
          <Image
            src={"/img/normal.png"}
            style={{
              width: iconWidth,
              height: iconHeight,
            }}
          />
          <Line />
        </Answer>

        <Answer
          className={
            hasValue && answer === 2 ? classColor[answer ?? ""] : "orange"
          }
          onClick={() => onAnswerChange({ Id: question.id, Answer: 2 })}
        >
          <Image
            src={"/img/sad.png"}
            style={{
              width: iconWidth,
              height: iconHeight,
            }}
          />
          <Line />
        </Answer>

        <Answer
          className={
            hasValue && answer === 1 ? classColor[answer ?? ""] : "red"
          }
          onClick={() => onAnswerChange({ Id: question.id, Answer: 1 })}
        >
          <Image
            src={"/img/angry.png"}
            style={{
              width: iconWidth,
              height: iconHeight,
            }}
          />
          <Line />
        </Answer>
      </AnswersContainer>
    </Container>
  );
};
