import { useRoutes, Navigate } from "react-router";
import App from "../App";
import { SuccessPage } from "../pages/successPage";
import { FailurePage } from "../pages/failurePage";
import { useEffect } from "react";

function RedirectToExternal() {
  useEffect(() => {
    window.location.href = "https://kesay.sa";
  }, []);
  return null;
}

export default function Router() {
  return useRoutes([
    {
      path: "",
      children: [
        {
          path: "",
          element: <RedirectToExternal />,
        },
        {
          path: "/:id",
          element: <App />,
        },
        {
          path: "/success",
          element: <SuccessPage />,
        },
        {
          path: "/error",
          element: <FailurePage />,
        },
      ],
    },
  ]);
}
